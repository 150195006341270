import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"

const ProjectDetails = ({ data: { markdownRemark, bg_2, choco, choco_froggies,golosina_chao,harina_pan } }) => {


    let imgTips = bg_2.childImageSharp.fluid.src;
    let idTip = markdownRemark.frontmatter.type;
    if(idTip === "chocolates"){
        imgTips = choco_froggies.childImageSharp.fluid.src
    }else if(idTip === "alimentos"){
        imgTips = harina_pan.childImageSharp.fluid.src
    }else if(idTip === "golosinas"){
        imgTips = golosina_chao.childImageSharp.fluid.src
    }



    return (
        <Layout changeHeader={2}>
            <SEO title={`Detalles del Tips`} />

            <section className="ftco-section ftco-services-2" style={{ background: '#f5f5f5' }} id="tips">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-5" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                        <div className="col-md-7 heading-section text-center ">
                            <span className="subheading">nuestros tips</span>
                            <h2 className="mb-4">Detalles del Tips</h2>
                            <p>Ofrecemos a nuestros aliados los mejores tips que facilitarán mucho sus vidas.</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-6 col-lg-5 d-flex coach-wrap" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <div className="img d-flex align-self-stretch align-items-center " >
                                <img className="img-fluid" src={imgTips} alt="logo de tips"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 pl-lg-5 py-5 coach-wrap">
                            <div className="py-md-5">
                                <div className="row justify-content-start pb-3">
                                    <div className="col-md-12 heading-section " data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                        <span className="subheading">#Tips</span>
                                        <h2 className="mb-4" style={{ fontSize: '34px', textTransform: 'capitalize' }}>
                                            {markdownRemark.frontmatter.title}
                                        </h2>
                                        <p dangerouslySetInnerHTML={{ __html: markdownRemark.html }}></p>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 text-center">
                            <Link to="/tips" className="btn btn-primary px-5 py-3 mt-3">Regresar</Link>
                        </div>

                    </div>
                </div>
            </section>



        </Layout>
    )
}

export default ProjectDetails

export const pageQuery = graphql`
  query FacByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        path
        code
        type
      }
    },

    allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              path
              image
            }
            html
          }
        }
      }
      bg_2: file(relativePath: {eq: "bg_2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 800) {
            src
          }
        }
      }
    image1: file(relativePath: { eq: "bg_1.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    choco: file(relativePath: { eq: "choco.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    harina_pan: file(relativePath: { eq: "harina_pan.png" }) {
        childImageSharp {
            fluid(maxWidth: 188) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    golosina_chao: file(relativePath: { eq: "golosina_chao.png" }) {
        childImageSharp {
            fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    choco_froggies: file(relativePath: { eq: "choco_froggies.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 188) {
                ...GatsbyImageSharpFluid
            }
        }
    }


  }
`
